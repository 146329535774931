@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family: "Oswald", sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Default */

.outer-container {
  max-width: 1440px;
}

/* header tags */
h1 {
  font-size: 32px;
  font-weight: normal;
  letter-spacing: -0.025em;
}
h2 {
  font-size: 30px;
  font-weight: 500; /* medium */
}
h3 {
  font-size: 26px;
  font-weight: 400; /* semibold */
}
h4 {
  font-size: 23px;
  font-weight: 600; /*medium*/
  letter-spacing: -0.025em;
}
h5 {
  font-size: 20px;
  letter-spacing: -0.025em;
  font-weight: 300; /*light*/
}
h6 {
  font-size: 18px;
  letter-spacing: -0.025em;
  font-weight: 700; /*bold*/
}

textarea {
  field-sizing: content;
}

/* Subtitile */
.subtitle-1 {
  font-size: 20px;
  letter-spacing: -0.025em;
}

/* body text */
.body-text {
  font-size: 16px;
  letter-spacing: 0.32px;
  font-weight: normal;
  line-height: 135%;
}

.body-text-2 {
  font-size: 16px;
  letter-spacing: 0.7px;
  font-weight: normal;
  line-height: 140%;
}

.button-text {
  font-size: 18px;
  font-weight: 600; /* semibold */
}

.button-text-s {
  font-size: 18px;
  font-weight: normal;
}


.nav-text {
  font-size: 16px;
  letter-spacing: 0.2px;
  font-weight: 500; /*semibold*/
  line-height: 120%;
}

.text-shadow-black {
  text-shadow: 2px 5px 6px rgb(100, 100, 100)
}

.font-roboto {
  font-family: "Roboto", sans-serif !important;
}

.container-padding {
  padding-left: 118px;
  padding-right: 118px;
  width: 1440px;
  margin: 0 auto;
}

.container-full {
  width: 100%;
}

body {
  background-color: #F6F6F6;
}