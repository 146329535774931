.outer-grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 48px;
}

.inner-grid-container {
    display: grid;
    gap: 5px;
    grid-template-columns: 1fr 1fr 1fr;
}

.big-image {
    grid-row: span 2;
    grid-column: span 2;
}


.small-image {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
}
.small-image-2 {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
}