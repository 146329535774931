.news-grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    padding: 12px;
}

.news-big-item {
    grid-column: span 2;
    grid-row: span 2;
}